import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, model, OnInit} from '@angular/core';
import {ActivatedRoute, ResolveFn, RouterLink} from '@angular/router';
import {TileComponent} from '../../controls/tile/tile';
import {APIService} from '../../services/api.service';
import {AuthService} from '../../services/authService';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IPackageData} from '../../services/packageTypes';
import {ToastUIEditorComponent} from '../../../oex-ui-kit/components/toastui-editor/toastui-editor.component';
import {IRecommendationsResponse} from '../../services/api.types';
import {ModalService} from '../../services/modal.service';
import {ProgressService} from '../../../oex-ui-kit/services/progress.service';

export const hihRecommendationsResolver: ResolveFn<any> = () => {
  const api = inject(APIService);
  const auth = inject(AuthService);
  if (!auth.getAdministratedCompany()) {
    return;
  }
  return api.getHihRecommendations();
}

@Component({
  standalone: true,
  selector: 'oex-hih-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrl: './recommendations.component.scss',
    imports: [
        TileComponent,
        RouterLink,
        ToastUIEditorComponent
    ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationsComponent implements OnInit {
  data$ = this.route.data.pipe(takeUntilDestroyed());
  model?: IRecommendationsResponse;

  constructor(public auth: AuthService,
              private api: APIService,
              private modal: ModalService,
              private ps: ProgressService,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.data$.subscribe(d => {
      if (!d.data) {
        return;
      }
      this.model = d.data;
    });
  }

  async onGetRecommendationsClick() {
    const nws = this.auth.getAdministratedCompany()?.nws;
    if (!nws || !this.model?.recomReq) {
      return;
    }
    this.ps.show();
    try {
      const model = await this.api.saveHihRecommendationText(nws, this.model?.recomReq);
      this.model = model;
      this.cdr.detectChanges();
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }
}
